<script>
export default {
  props: {
    block: {
      type: Object,
      default: null,
    },
  },
  computed: {    
    height() {
      return this.block?.fields?.Height 
            ? {height: this.block.fields.Height} 
            : "";
    },
  }
};
</script>

<template>
  <div :style="height">
  </div>
</template>
